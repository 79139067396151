import {
  Box,
  Button,
  Skeleton,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BACK_ICON from "../../assets/icons/back.svg";
import LOCATION_ICON from "../../assets/icons/location.svg";
import STORE_ICON from "../../assets/icons/store-gray.svg";
import STORE_LOGO from "../../assets/images/gas-station.png";
import CustomWeekRangeInput from "../../components/CustomWeekRangeInput";
import DashboardTab from "./DashboardTab";
import Efficiency from "./EfficiencyTab";
import OverviewTab from "./OverviewTab";
import SchedulesTab from "./SchedulesTab";
import { useStore } from "./storeHelper";
import { BACK, CONTENT_TEXT, TITLE } from "./styles";

const PAGE_WRAPPER: SxProps = {
  background: "#FFFFFF",
  borderRadius: "12px",
  padding: 3,
  flex: 1,
  overflow: "auto",
};

export default function Store() {
  const {
    isStoreLoading,
    isDashboardLoading,
    isScheduleLoading,
    storeDetails,
    selectedTab,
    dashboardChartData,
    storeKPIData,
    storeAlertsData,
    scheduleData,
    scheduleChartData,
    storeId,
    isFromDistrict,
    isFromVP,
    isFromROM,
    weeklyDemandStaffingData,
    weeklyEfficiencytrendsData,
    daywiseEfficiencytrendsData,
    efficiencyTarget,
    jobs,
    SMDetails,
    handleTabChange,
    getFullAddress,
    getSelectedWeek,
    fetchPreviousWeekSchedules,
    saveCurrentSchedules,
    setIsStoreUpdate,
  } = useStore();
  const navigate = useNavigate();
  const getTabContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <DashboardTab
            chartData={dashboardChartData}
            kpiData={storeKPIData}
            alerts={storeAlertsData}
            isLoading={isDashboardLoading}
          />
        );
      case 1:
        return (
          <Efficiency
            weeklyDemandStaffingData={weeklyDemandStaffingData}
            weeklyEfficiencytrendsData={weeklyEfficiencytrendsData}
            daywiseEfficiencytrendsData={daywiseEfficiencytrendsData}
            efficiencyTarget={efficiencyTarget}
          />
        );
      case 2:
        return (
          <SchedulesTab
            scheduleData={scheduleData}
            isLoading={isScheduleLoading}
            scheduleChartData={scheduleChartData}
            jobs={jobs}
            SMDetails={SMDetails}
            storeData={storeDetails}
            fetchPreviousWeekSchedules={fetchPreviousWeekSchedules}
            saveCurrentSchedules={saveCurrentSchedules}
          />
        );
      case 3:
        if (isFromDistrict || isFromVP || isFromROM) {
          return (
            <OverviewTab
              storeData={storeDetails}
              isLoading={isStoreLoading}
              isEdit={isFromVP || isFromROM}
              jobs={jobs}
              onSave={() => setIsStoreUpdate(true)}
            />
          );
        } else {
          return (
            <OverviewTab storeData={storeDetails} isLoading={isStoreLoading} />
          );
        }
      default:
        break;
    }
    // switch (selectedTab) {
    //   case 0:
    //     return (
    //       <DashboardTab
    //         chartData={dashboardChartData}
    //         kpiData={storeKPIData}
    //         alerts={storeAlertsData}
    //         isLoading={isDashboardLoading}
    //       />
    //     );
    //   case 1:
    //     if (isFromDistrict || isFromVP || isFromROM) {
    //       return (
    //         <Efficiency
    //           weeklyDemandStaffingData={weeklyDemandStaffingData}
    //           weeklyEfficiencytrendsData={weeklyEfficiencytrendsData}
    //           daywiseEfficiencytrendsData={daywiseEfficiencytrendsData}
    //           efficiencyTarget={efficiencyTarget}
    //         />
    //       );
    //     } else {
    //       return (
    //         <SchedulesTab
    //           scheduleData={scheduleData}
    //           isLoading={isScheduleLoading}
    //           scheduleChartData={scheduleChartData}
    //           jobs={jobs}
    //           SMDetails={SMDetails}
    //           storeData={storeDetails}
    //           fetchPreviousWeekSchedules={fetchPreviousWeekSchedules}
    //           saveCurrentSchedules={saveCurrentSchedules}
    //         />
    //       );
    //     }
    //   case 2:
    //     if (isFromDistrict || isFromVP || isFromROM) {
    //       return (
    //         <SchedulesTab
    //           scheduleData={scheduleData}
    //           isLoading={isScheduleLoading}
    //           scheduleChartData={scheduleChartData}
    //           jobs={jobs}
    //           SMDetails={SMDetails}
    //           storeData={storeDetails}
    //           fetchPreviousWeekSchedules={fetchPreviousWeekSchedules}
    //           saveCurrentSchedules={saveCurrentSchedules}
    //         />
    //       );
    //     } else {
    //       return (
    //         <OverviewTab storeData={storeDetails} isLoading={isStoreLoading} />
    //       );
    //     }

    //   case 3:
    //     return (
    //       <OverviewTab
    //         storeData={storeDetails}
    //         isLoading={isStoreLoading}
    //         isEdit={isFromVP || isFromROM}
    //         jobs={jobs}
    //         onSave={() => setIsStoreUpdate(true)}
    //       />
    //     );

    //   default:
    //     break;
    // }
  };

  const checkIsVisible = () => {
    // if (isFromDistrict || isFromVP || isFromROM) {
    //   if (selectedTab !== 3) {
    //     return true;
    //   }
    //   return false;
    // } else {
    //   if (selectedTab !== 2) {
    //     return true;
    //   }
    //   return false;
    // }
    if (selectedTab !== 3) {
      return true;
    }
    return false;
  };

  return (
    <Stack spacing={2} sx={PAGE_WRAPPER}>
      {storeId && (
        <Stack
          spacing={1}
          direction={"row"}
          alignItems={"center"}
          flex={1}
          sx={{ maxHeight: 20 }}
        >
          <Button
            startIcon={
              <img src={BACK_ICON} alt="back-icon" height={24} width={24} />
            }
            onClick={() => {
              isFromDistrict
                ? navigate("..", { state: { isBackFromStore: true } })
                : navigate(-1);
            }}
          >
            <Typography sx={BACK}>Back</Typography>
          </Button>
        </Stack>
      )}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={3} flex={1}>
          <img src={STORE_LOGO} alt="store-icon" width={72} height={72} />
          <Stack spacing={1.5}>
            <Typography sx={TITLE}>
              {isStoreLoading ? (
                <Skeleton width={150} height={20} />
              ) : storeDetails?.storeCode ? (
                `Store #${storeDetails?.storeCode}`
              ) : (
                ""
              )}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <img src={STORE_ICON} alt="store" />
                <Typography sx={CONTENT_TEXT}>
                  {isStoreLoading ? (
                    <Skeleton width={35} />
                  ) : (
                    storeDetails?.storeCode || ""
                  )}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <img src={LOCATION_ICON} alt="location" />
                <Typography sx={CONTENT_TEXT}>
                  {isStoreLoading ? <Skeleton width={260} /> : getFullAddress()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {checkIsVisible() && (
          <Stack
            flex={0.5}
            justifyContent={"flex-end"}
            direction={"row"}
            alignItems={"center"}
          >
            <CustomWeekRangeInput onWeekChange={getSelectedWeek} />
          </Stack>
        )}
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="store tabs"
        >
          <Tab label="Dashboard" value={0} />
          <Tab label="Efficiency" value={1} />
          <Tab label="Schedules" value={2} />
          <Tab label="Store Info" value={3} />
          {/* {(isFromDistrict || isFromVP || isFromROM) && (
            <Tab label="Efficiency" value={1} />
          )}
          <Tab
            label="Schedules"
            value={isFromDistrict || isFromVP || isFromROM ? 2 : 1}
          />
          <Tab
            label="Store Info"
            value={isFromDistrict || isFromVP || isFromROM ? 3 : 2}
          /> */}
        </Tabs>
      </Box>
      {getTabContent()}
    </Stack>
  );
}
