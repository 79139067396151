import { axiosInstance } from "./serviceConfig";

const storeService = {
  getUser: () => axiosInstance.get(`/api/users/me`),
  getStore: () => axiosInstance.get(`/api/stores/smstores`),
  getStoreById: (storeId: string) =>
    axiosInstance.get(`/api/stores/${storeId}`),
  getHoursByWeek: (storeId: number, startWeek: string) =>
    axiosInstance.get(`/api/storemanager/weeklyhours/${storeId}/${startWeek}`),
  getStoreKPIByWeek: (storeId: number, startWeek: string) =>
    axiosInstance.get(`/api/storemanager/kpi/${storeId}/${startWeek}`),
  getAlertsByWeek: (startWeek: string) =>
    axiosInstance.get(`/api/storemanager/keyalerts/${startWeek}`),
  getJobs: () => axiosInstance.get("/api/jobs"),
  getSchedules: (storeId: number, startWeek: string) =>
    axiosInstance.get(
      `/api/schedules?StoreId=${storeId}&ScheduleDate=${startWeek}`,
    ),
  getPreviousWeekSchedules: (payload: {
    storeId: number;
    scheduleDate: string;
  }) => axiosInstance.post(`/api/schedules/copyfrompreviousweek`, payload),
  saveSchedules: (payload: any) =>
    axiosInstance.post(`/api/schedules`, payload),
  getScheduleChartData: (payload: { storeId: number; scheduleDate: string }) =>
    axiosInstance.post(`/api/schedules/dailyschedulechart`, payload),
  getWeeklyDemandStaffingData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/vp/stores/${storeId}/efficiency/graphdata/weeklydemandnstaffing?startDate=${startDate}`,
    ),
  getWeeklyEfficiencyTreadsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/vp/stores/${storeId}/efficiency/graphdata/weeklyefficiencytrends?startDate=${startDate}`,
    ),
  getDayWiseEfficiencyTreadsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/vp/stores/${storeId}/efficiency/graphdata/daywiseefficiencytrends?startDate=${startDate}`,
    ),
  getEfficiencyTarget: (storeId: number, startWeek: string) =>
    axiosInstance.get(
      `/api/vp/store/${storeId}/efficiency?startDate=${startWeek}`,
    ),
  getManagers: () => axiosInstance.get("api/activedirectory/roles/users"),
  getServices: () => axiosInstance.get("/api/stores/storeservices"),
  getManagersByRegionId: (regionId: number | string) =>
    axiosInstance.get(`api/activedirectory/roles/users?regionId=${regionId}`),
  getHistoricAlerts: () =>
    axiosInstance.get(`/api/notification/historicalkeyalerts`),
  saveStore: (payload: any) => axiosInstance.post(`/api/stores`, payload),
  updateAlert: (id: number, startWeek: string, days: number) =>
    axiosInstance.put(
      `/api/notification/updateisread/${id}/${startWeek}/${days}`,
    ),
  getSMEfficiencyTarget: (storeId: number, startWeek: string) =>
    axiosInstance.get(
      `/api/storemanager/store/${storeId}/efficiency?startDate=${startWeek}`,
    ),
  getSMWeeklyDemandStaffingData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/storemanager/stores/${storeId}/efficiency/graphdata/weeklydemandnstaffing?startDate=${startDate}`,
    ),
  getSMWeeklyEfficiencyTrendsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/storemanager/stores/${storeId}/efficiency/graphdata/weeklyefficiencytrends?startDate=${startDate}`,
    ),
  getSMDayWiseEfficiencyTrendsData: (storeId: number, startDate: string) =>
    axiosInstance.get(
      `api/storemanager/stores/${storeId}/efficiency/graphdata/daywiseefficiencytrends?startDate=${startDate}`,
    ),
};

export default storeService;
